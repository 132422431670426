'use strict';


angular.module('kljDigitalLibraryApp')

.controller('CheckoutPaymentCtrl', ["$rootScope", "$scope", "$state", "Auth", "CheckoutService", "OrderService", "ngToast","$localStorage",
    function($rootScope, $scope, $state, Auth, CheckoutService, OrderService, ngToast, $localStorage) {

        $rootScope.title = 'Order Details';
        const SUCCESS = 'success';
        $scope.couponCodeSubscription =  localStorage.getItem("couponCodeSubscription");
        var onInit = function() {
            $localStorage.title=undefined
            getOrder($state.params.ordernumber);

            // if($state.params.status === SUCCESS){
            // 	$scope.paymentSuccess = true;

            // 	$scope.paymentTitle = 'Payement Success';
            // 	$scope.headingStyle = {
            // 		'background-color':'#3c763d'
            // 	}
            // }else{
            // 	$scope.paymentFailed = true;
            // 	$scope.paymentTitle = 'Payement Failed';
            // 	$scope.headingStyle = {
            // 		'background':'#d14836'
            // 	}
            // }
        }

        //Get order details by order number
        var getOrder = function(id) {

            OrderService.get(id)

            .then(function(data) {
                
                $scope.orderDetails = data;
                if ($scope.orderDetails && $scope.orderDetails.coupons && $scope.orderDetails.coupons.length > 0) {
                    if ($scope.orderDetails.coupons[0] === null) {
                        $scope.orderDetails.coupons.splice(0, 1);
                    }
                }
            })

            .catch(function(error) {
                ngToast.danger("Failed to load order details");
            })
        }

        onInit();
    }
]);